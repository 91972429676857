import { AppConst } from "@app/const.generated";
import { environment } from "@env/environment";
import { WorkTaskStatus } from "@wearewarp/types";
import { InChargeHistoryAswerType } from "@wearewarp/types/data-model/types/WorkTask";

export class Const {

  // Route
  public static readonly routeLogin = `/login`;
  public static readonly routeVerifyLogin = `/verify`;
  public static readonly routeDashboard = '/dashboard';
  public static readonly routeOperatorPoolList = `${this.routeDashboard}/operator-pools`;

  public static routeAdminDispatch(jobId) { return `${environment.webAdminUrl}/dashboard/dispatch/${jobId}`; }
  public static routeWorkSpecificTask(taskId) { return `${environment.webWorkUrl}/dashboard/task-queue/${taskId}`; }

  // API URI backend origin
  public static readonly APIURI_TIMEOUT_TEST = 'http://google.com:81';
  public static readonly API_SEARCH_US_CITIES = 'https://gw.wearewarp.com/us-cities/v1/search/city';
  public static readonly APIURI_MY_PROFILE = Const.API_ME('my_profile');
  public static readonly APIURI_USERS = 'users';
  public static readonly APIURI_CONVERSATIONS = 'v2/conversations';
  public static readonly APIURI_DRIVERS = 'drivers';
  public static readonly APIURI_CARRIERS = 'carriers';
  public static readonly APIURI_CLIENTS = 'clients';
  public static readonly APIURI_WAREHOUSES = 'warehouses';

  public static APIV2(path: string): string { return `v2/${path}` };
  public static API_ME(action: string = null): string { return action ? `me/${action}` : 'me' }

  
  // API backend work queue
  public static API_PUBLIC(action: string): string { return `v1/p/${action}` };
  public static API_SESSION(): string { return `${Const.API_PUBLIC('ss?e=1')}` };

  public static API_WORK_TASK(path: string) { return `v1/w/tasks/${path}` }
  public static readonly API_LIST_TASK = Const.API_WORK_TASK('');
  public static readonly API_FETCH_TASK = Const.API_WORK_TASK('get-queue-task');
  public static API_COMPLETE_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/complete-task`) };
  public static API_AUTO_COMPLETE_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/auto-complete-task`) };
  public static API_GET_LOG_HISTORY(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/get-histories`) };
  public static API_UNASSIGN_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/un-assign-task`) };
  public static API_ASSIGN_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/assign-task`) };
  public static API_BULK_ASSIGN_TASK(): string { return Const.API_WORK_TASK('bulk-assign') };
  public static API_UPDATE_LOG_HISTORY(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/update-history`) };
  public static API_RESUME_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}/resume-task`) };
  public static API_REMOVE_TASK(taskId: string): string { return Const.API_WORK_TASK(`${taskId}`) }; 
  public static API_REMOVE_ALL_ROUTE_TASKS(jobId: string): string { return `v1/w/jobs/${jobId}/remove-all-route-tasks` };
  public static readonly APIURI_LIST_OPERATORS = `v1/users/list-operators`;
  public static readonly APIURI_LIST_CLIENTS = `v1/clients`;
  // API operator pools
  public static readonly APIURI_OPERATOR_POOLS = 'v1/operator-pools';
  
  public static MAPBOX_STYLE(name: string): string {
    return `mapbox://styles/mapbox/${name}`;
  }

  public static readonly DATETIME_FORMAT_FROM_DB = 'YYYY-MM-DD HH:mm:ss';
  public static readonly FORMAT_GUI_DATETIME_SHORT = 'M/D/YY, h:mm a';
  public static readonly FORMAT_GUI_DATE = 'MMM D, YYYY';
  public static readonly FORMAT_GUI_DATETIME = 'MMM D, YYYY, h:mm a';
  public static readonly MOBILE_WIDTH = 568;

  public static readonly ENCRYPT_KEY = 'y1pSrKRLLkCQGbt0rqA5DJh3XnjDG3j9';
  public static readonly ENCRYPT_IV = 'U0mkzdFK7naqEn60';
  public static readonly PAGINATION_LIMIT = 10;

  public static readonly WORK_MANAGER_APP_ID: number = 15;
  public static readonly APP_HTTP_HEADER: string = `${Const.WORK_MANAGER_APP_ID};${AppConst.Version};${AppConst.BuildWhen.replace(/[\/\s:]/g, '')}`;

  public static readonly API_SEARCH_START_WITH = 1;
  public static readonly API_SEARCH_END_WITH = 2;
  public static readonly API_SEARCH_INCLUDE = 3;

  public static readonly TrackingTask = {
    routeConfirmation: 'routeConfirmation',
    otpConfirmation: 'otpConfirmation',
    etaConfirmation: 'etaConfirmation',
    arrivalConfirmation: 'arrivalConfirmation',
    departureConfirmation: 'departureConfirmation',
    enrouteTracking: 'enrouteTracking',
  }

  public static get TrackingTaskLabel() {
    return {
      routeConfirmation: 'Confirm Booking',
      otpConfirmation: '24 hour check',
      etaConfirmation: 'Confirm ETA',
      arrivalConfirmation: 'Confirm Arrival',
      departureConfirmation: 'Confirm Departure',
      enrouteTracking: 'En-route Tracking',
    }
  }

  public static get WorkTaskStatus(): {[key: string]: WorkTaskStatus} {
    return {
      onHold: 'onHold',
      pending: 'pending',
      inProcess: 'inProcess',
      paused: 'paused',
      completed: 'completed',
      escalated: 'escalated'
    }
  }

  public static WorkTaskStatusText(status) {
    switch (status) {
      case 'onHold': return 'On Hold';
      case 'pending': return 'Open';
      case 'inProcess': return 'Working';
      case 'completed': return 'Completed';
      case 'escalated': return 'Escalated';
      case 'paused': return 'Paused';
      default: return 'N/A';
    }
  }

  public static readonly JobStatus = {
    created: "created",
    inProgress: "inProgress",
    canceled: "canceled",
    completed: "completed",
  }

  public static getJobStatusText(status) {
    if (!status) {
      return 'Created';
    }
    switch (status) {
      case this.JobStatus.created: return  'Created';
      case this.JobStatus.inProgress: return 'In Progress';
      case this.JobStatus.completed: return 'Completed';
      case this.JobStatus.canceled: return 'Canceled';
      default: return status;
    }
  }

  public static readonly StopStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static readonly RouteTaskStatus = {
    created: "created",
    enroute: "enroute",
    arrived: "arrived",
    succeeded: "succeeded",
    failed: "failed",
    canceled: "canceled",
    pickupFailed: "pickupFailed"
  }

  public static getStopStatusText(status) {
    switch (status) {
      case this.StopStatus.created: return  'Created';
      case this.StopStatus.enroute: return 'En-route';
      case this.StopStatus.arrived: return 'Arrived';
      case this.StopStatus.succeeded: return 'Succeeded';
      case this.StopStatus.failed: return 'Failed';
      case this.StopStatus.canceled: return 'Canceled';
      case this.StopStatus.pickupFailed: return 'Pickup Failed';
      default: return status;
    }
  }

  public static getTaskTypeText(status) {
    switch(status) {
      case "PICKUP": return "Pickup";
      case "DROPOFF": return "Dropoff";
      case "TRANSIT": return "Transit";
      case "RETURN": return "Return";
      case "RETURN_DEPOT": return "Return Depot";
      default: return status;
    }
  }

  public static readonly ShipmentType = {
    FTL: "FTL",
    LTL: "LTL",
    PARCEL: "PARCEL"
  }

  public static readonly RouteTaskType = {
    PICKUP: 'PICKUP',
    DROPOFF: 'DROPOFF'
  }

  public static readonly UpdateByCollection = {
    users: 'users',
  }

  public static readonly DriverStatus = {
    active: 1
  }

  public static readonly ManageTaskTabIndex = {
    escalation: 0,
    workqueue: 1,
    resolved: 2,
    paused: 3
  }

  public static getTaskAnswerText(answerType: InChargeHistoryAswerType) {
    switch (answerType) {
      case 'contactFailed': return 'Contact failed';
      // confirm booking
      case 'keepRoute': return 'Carrier keep route';
      case 'waitingForConfirmation': 'Carrier need waiting for confirmation';
      case 'declinedRoute': return 'Carrier declined route';
      // confirm otp (24h check)
      case 'driverConfirmed': return 'Driver confirmed';
      case 'incorrectDriver': return 'Incorrect driver';
      case 'contactedViaSms': return 'Contacted via SMS/Email';
      // confirm eta
      case 'driverOnTime': return 'Driver on time';
      case 'driverLate': return 'Driver late';
      case 'driverNotPickup': return `Driver can't pickup`;
      // additional answer:
      case 'pauseTask': return 'Pause task';
      case 'isUnAssigned': return 'Un-assigned operator';
      default: return 'N/A';
    }
  }

  public static readonly listFilterClients = [
    { label: 'Imperfect Food', value: '01H22NK3KGW06WNHSJCA9PRYTH' },
    { label: 'Gopuff', value: '01HRZEJV34B82ZVT2B1TE06VWX' },
    { label: 'Gopuff (Bevmo!)', value: '01J2CW1K337KRCZCV8NPS19VSC' },
  ]

  public static readonly OperatorPoolTypeLabel = {
    tracktrace: 'Track & Trace',
  }
}
